import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IfRole from '@realcity/web-frame/lib/components/Auth/IfRole';
import { Roles } from '../../constants';
import ConfigurationDialog from '../../containers/ConfigurationModal';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ExtendedDevice } from '../../../../common/interfaces';
import { clearCommand, uploadDeviceLog } from '../../store/actions';
import { FormattedMessage } from 'react-intl';
import { Command } from '../../store/reducers';
import { AppState } from '../../store/state';

interface Props {
    device: ExtendedDevice;
    latestCommand: Command;
    uploadDeviceLog: typeof uploadDeviceLog;
    clearCommand: typeof clearCommand;
}

const useStyles = makeStyles({
    circularProgress: {
        marginLeft: 10,
    },
});

const DeviceDropDownMenu = (props: Props) => {
    const { device, latestCommand, uploadDeviceLog, clearCommand } = props;
    const classes = useStyles();

    const [configurationDialogOpen, setConfigurationDialogOpen] = useState(false);
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);

    const cleanupAndClose = () => {
        clearCommand();
        setMenuOpen(false);
    };

    const onConfigItemClick = () => {
        setConfigurationDialogOpen(true);
        setMenuOpen(false);
    };

    const onLogUploadItemClick = () => {
        uploadDeviceLog(device.deviceId);
    };

    const onConfigDialogClose = () => {
        setConfigurationDialogOpen(false);
    };

    const onMenuButtonClick = (event: any) => {
        setMenuAnchor(event.currentTarget);
        setMenuOpen(true);
    };

    if (menuOpen && latestCommand.state === 'success') {
        cleanupAndClose();
    }

    return (
        <>
            <IconButton onClick={onMenuButtonClick}>
                <MoreVertIcon/>
            </IconButton>
            <Menu open={menuOpen}
                  anchorEl={menuAnchor}
                  onClose={cleanupAndClose}
                  keepMounted>
                <MenuItem onClick={onConfigItemClick}>
                    <FormattedMessage id="devices.drop-down.configuration"/>
                </MenuItem>
                <IfRole role={Roles.Write}>
                    <MenuItem onClick={onLogUploadItemClick}>
                        <FormattedMessage id="devices.drop-down.upload-log"/>
                        {latestCommand.state === 'in-progress' &&
                            <CircularProgress className={classes.circularProgress} size={24}/>
                        }
                    </MenuItem>
                </IfRole>
            </Menu>
            <ConfigurationDialog open={configurationDialogOpen} device={device} onClose={onConfigDialogClose}/>
        </>
    );
};

export default connect(
    ({ latestCommand }: AppState) => ({ latestCommand }),
    { uploadDeviceLog, clearCommand })
(DeviceDropDownMenu);
