import { ExtendedDataset, ExtendedDevice } from '../../../common/interfaces';
import { AppAction, BaseFailedAction, BaseSuccessAction } from './actions';
import { CommandNotification, CommandState } from './state';

export interface FetchDataType<T> {
    data: T;
    error?: any;
    loading: boolean;
    lastLoadTime?: Date;
}

export interface Command {
    state: CommandState;
}

export type Devices = FetchDataType<ExtendedDevice[]>;
export type Datasets = FetchDataType<ExtendedDataset[]>;

function commandReducer(state: Command = getInitialCommandState(), action: AppAction): Command {
    switch (action.type) {
        case 'COMMAND_CLEAR':
            return { state: 'not-started' };
        case 'COMMAND_START':
            return { state: 'in-progress' };
        case 'COMMAND_SUCCESS':
            return { state: 'success' };
        case 'COMMAND_FAILED':
            return { state: 'error' };
    }

    return state;
}

function commandNotificationReducer(state: CommandNotification[] = [], action: AppAction) : CommandNotification[] {
    switch (action.type) {
        case 'COMMAND_CLOSE_NOTIFICATION':
            return state.filter(n => n.id !== action.id);
        case 'COMMAND_SUCCESS':
            return [...state, {
                id: action.commandId,
                type: action.commandType,
                target: action.target,
                result: 'success',
            }];
        case 'COMMAND_FAILED':
            return [...state, {
                id: action.commandId,
                type: action.commandType,
                target: action.target,
                result: 'error',
            }];
    }

    return state;
}

function devicesReducer(state: Devices = getInitialState(), action: AppAction): Devices {
    switch (action.type) {
        case 'FETCH_DEVICES_START':
            return createFetchStartState<ExtendedDevice[]>(state);
        case 'FETCH_DEVICES_SUCCESS':
            return createFetchSuccessState<ExtendedDevice[]>(action);
        case 'FETCH_DEVICES_FAILED':
            return createFetchFailedState<ExtendedDevice[]>(state, action);
    }
    return state;
}

function datasetsReducer(state: Datasets = getInitialState(), action: AppAction): Datasets {
    switch (action.type) {
        case 'FETCH_DATASETS_START':
            return createFetchStartState<ExtendedDataset[]>(state);
        case 'FETCH_DATASETS_SUCCESS':
            return createFetchSuccessState<ExtendedDataset[]>(action);
        case 'FETCH_DATASETS_FAILED':
            return createFetchFailedState<ExtendedDataset[]>(state, action);
    }
    return state;
}

const getInitialCommandState = () => ({ state: 'not-started' as CommandState });
const getInitialState = () => ({ data: [], loading: false });

function createFetchStartState<T>(state: FetchDataType<T>): FetchDataType<T> {
    return { ...state, loading: true, error: null };
}

function createFetchSuccessState<T>(action: BaseSuccessAction<T>): FetchDataType<T> {
    return { data: action.data, error: null, loading: false, lastLoadTime: action.time };
}

function createFetchFailedState<T>(state: FetchDataType<T>, action: BaseFailedAction): FetchDataType<T> {
    return { ...state, loading: false, error: action.error };
}

const reducers = {
    devices: devicesReducer,
    datasets: datasetsReducer,
    latestCommand: commandReducer,
    commandNotifications: commandNotificationReducer,
};

export default reducers;

