import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { AppState, CommandNotification } from '../../store/state';
import { NOTIFICATION_MESSAGES } from '../../utils';
import NotificationSnackbar from './NotificationSnackbar';

const CommandNotifications = () => {
    const commandNotifications = useSelector<AppState, CommandNotification[]>(state => state.commandNotifications);

    return (
        <>
            {commandNotifications
                .map((n) => {
                    const message = NOTIFICATION_MESSAGES[n.result];

                    return <NotificationSnackbar key={n.id}
                                                 notificationId={n.id}
                                                 origin={'bottom-right'}
                                                 open
                                                 variant={n.result}>
                            <FormattedMessage id={message} values={{ target: n.target }}/>
                    </NotificationSnackbar>;
                })
            }
        </>
    );
};

export default CommandNotifications;
