import * as React from 'react';
import { useMemo } from 'react';
import { connect } from 'react-redux';
import { Datasets } from '../../store/reducers';
import { fetchDatasets } from '../../store/actions';
import { AppState } from '../../store/state';
import { COMMON_DATE_FORMAT } from '../../utils';
import { FormattedMessage, FormattedTime, injectIntl, IntlShape, WrappedComponentProps } from 'react-intl';
import { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { ExtendedDataset } from '../../../../common/interfaces';
import makeStyles from '@mui/styles/makeStyles';
import CommonTable from '../../components/CommonTable';

const createLocalizedColumns: (intl: IntlShape) => MRT_ColumnDef<ExtendedDataset>[] = intl => [
    {
        accessorKey: 'datasetId',
        header: intl.formatMessage({ id: 'datasets.table.id' }),
        filterFn: 'includesString',
    },
    {
        accessorKey: 'version',
        header: intl.formatMessage({ id: 'datasets.table.version' }),
        filterFn: 'includesString',
    },
    {
        accessorKey: 'validityStart',
        header: intl.formatMessage({ id: 'datasets.table.validity-start' }),
        filterFn: 'startsWith',
    },
    {
        accessorKey: 'validityEnd',
        header: intl.formatMessage({ id: 'datasets.table.validity-end' }),
        filterFn: 'startsWith',
    },
    {
        id: 'uploaded',
        accessorFn: row => new Date(row.uploaded),
        header: intl.formatMessage({ id: 'datasets.table.upload-date' }),
        Cell: ({ row }) => <FormattedTime value={row.original.uploaded} {...COMMON_DATE_FORMAT} />,
    },
    {
        accessorKey: 'isUpcoming',
        header: intl.formatMessage({ id: 'datasets.table.upcoming' }),
        Cell: ({ row }) => row.original.isUpcoming ? <FormattedMessage id="datasets.table.upcoming" /> : '',
        filterVariant: 'checkbox',
    },
    {
        accessorKey: 'isActive',
        header: intl.formatMessage({ id: 'datasets.table.active' }),
        Cell: ({ row }) => row.original.isActive ? <FormattedMessage id="datasets.table.active" /> : '',
        filterVariant: 'checkbox',
    },
];

const useStyles = makeStyles({
    activeDataSetRow: {
        backgroundColor: '#83F52C',
    },
});

interface Props extends WrappedComponentProps {
    datasets: Datasets;
    fetchDatasets: typeof fetchDatasets;
}

const DataSetsList: React.FC<Props> = ({ datasets, fetchDatasets, intl }) => {
    const { data } = datasets;

    const classes = useStyles();

    const columns = useMemo(() => createLocalizedColumns(intl), [intl]);

    const generateRowId = (row: ExtendedDataset) => row.datasetId;

    const muiTableBodyRowProps = (props: { row: MRT_Row<ExtendedDataset> }) => ({
        className: props.row.original.isActive ? classes.activeDataSetRow : undefined,
    });

    return (
        <CommonTable
            title={<FormattedMessage id="datasets.title" />}
            fetchResult={datasets}
            refresh={fetchDatasets}
            data={data}
            columns={columns}
            getRowId={generateRowId}
            initialState={{
                columnVisibility: {
                    isActive: false,
                },
            }}
            muiTableBodyRowProps={muiTableBodyRowProps}
        />
    );
};

export default connect(
    ({ datasets }: AppState) => ({ datasets }),
    { fetchDatasets },
)(injectIntl(DataSetsList));
