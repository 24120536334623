import * as React from 'react';
import { useCallback, useMemo } from 'react';
import CommandNotifications from '../../containers/CommandNotifications/FilteredCommandNotifications';
import { fetchDevices } from '../../store/actions';
import { connect } from 'react-redux';
import { Devices } from '../../store/reducers';
import { AppState, CommandNotification } from '../../store/state';
import { COMMON_DATE_FORMAT, convertDatasetDetailsToString } from '../../utils';
import { FormattedMessage, FormattedTime, injectIntl, IntlShape, WrappedComponentProps } from 'react-intl';
import { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { ExtendedDevice } from '../../../../common/interfaces';
import LongText from '../../components/LongText';
import DeviceActivityIcon from './DeviceActivityIcon';
import DatasetCell from '../../components/DataSets/DatasetCell';
import DeviceDropDownMenu from './DeviceDropDownMenu';
import CommonTable from '../../components/CommonTable';

const createLocalizedColumns: (intl: IntlShape) => MRT_ColumnDef<ExtendedDevice>[] = intl => [
    {
        accessorKey: 'online',
        header: intl.formatMessage({ id: 'devices.table.active' }),
        Cell: ({ row }) => (
            <DeviceActivityIcon activityStatus={row.original.online ? 'ACTIVE' : 'INACTIVE'} />
        ),
        size: 48,
        filterVariant: 'checkbox',
    },
    {
        accessorKey: 'deviceId',
        header: intl.formatMessage({ id: 'devices.table.id' }),
        Cell: ({ row }) => <LongText text={row.original.deviceId} />,
        size: 100,
    },
    {
        accessorKey: 'deviceName',
        header: intl.formatMessage({ id: 'devices.table.name' }),
        size: 100,
    },
    {
        accessorKey: 'vehicleId',
        header: intl.formatMessage({ id: 'devices.table.vehicle-id' }),
        size: 100,
        filterFn: 'includesString',
    },
    {
        accessorKey: 'appVersion',
        header: intl.formatMessage({ id: 'devices.table.app-version' }),
        filterFn: 'includesString',
    },
    {
        accessorKey: 'activeDatasetStatus',
        header: intl.formatMessage({ id: 'devices.table.active-dataset-status' }),
    },
    {
        accessorKey: 'activeDatasetVersion',
        header: intl.formatMessage({ id: 'devices.table.active-dataset-version' }),
        muiTableBodyCellProps: { sx: { textWrap: 'wrap' } },
        Cell: ({ row }) => (
            <DatasetCell status={row.original.activeDatasetStatus} datasetVersion={row.original.activeDatasetVersion} />
        ),
        filterFn: 'includesString',
    },
    {
        accessorKey: 'downloadedDatasetsStatus',
        header: intl.formatMessage({ id: 'devices.table.downloaded-dataset-status' }),
    },
    {
        id: 'downloadedDatasetsDetails',
        accessorFn: row => convertDatasetDetailsToString(row.downloadedDatasetsDetails || []),
        header: intl.formatMessage({ id: 'devices.table.downloaded-dataset-versions' }),
        muiTableBodyCellProps: { sx: { textWrap: 'wrap' } },
        Cell: ({ row, renderedCellValue }) => (
            <DatasetCell
                status={row.original.downloadedDatasetsStatus}
                datasetVersion={renderedCellValue}
            />
        ),
        filterFn: 'includesString',
    },
    {
        accessorKey: 'phoneNumber',
        header: intl.formatMessage({ id: 'devices.table.phone-number' }),
    },
    {
        id: 'lastUpdated',
        accessorFn: row => row.lastUpdated ? new Date(row.lastUpdated) : undefined,
        header: intl.formatMessage({ id: 'devices.table.updated' }),
        Cell: ({ row }) => (
            row.original.lastUpdated ? <FormattedTime value={row.original.lastUpdated} {...COMMON_DATE_FORMAT} /> : ''
        ),
    },
];

interface Props extends WrappedComponentProps {
    devices: Devices;
    commandNotifications: CommandNotification[];
    fetchDevices: typeof fetchDevices;
}

const DevicesList: React.FC<Props> = ({ intl, devices, fetchDevices }) => {
    const { data } = devices;

    const columns = useMemo(() => createLocalizedColumns(intl), [intl]);

    const generateRowId = (row: ExtendedDevice) => row.deviceId;

    const renderRowActions = useCallback(
        (props: { row: MRT_Row<ExtendedDevice> }) => (
            <DeviceDropDownMenu device={props.row.original} />
        ),
        [],
    );

    return (
        <>
            <CommandNotifications />

            <CommonTable
                title={<FormattedMessage id="devices.title" />}
                fetchResult={devices}
                refresh={fetchDevices}
                data={data}
                columns={columns}
                getRowId={generateRowId}
                initialState={{
                    columnVisibility: {
                        activeDatasetStatus: false,
                        downloadedDatasetsStatus: false,
                        phoneNumber: false,
                    },
                }}
                enableRowActions
                positionActionsColumn="last"
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: intl.formatMessage({ id: 'devices.table.edit' }),
                        muiTableBodyCellProps: {
                            align: 'center',
                        },
                    },
                }}
                renderRowActions={renderRowActions}
            />
        </>
    );
};

export default connect(
    ({ devices }: AppState) => ({ devices }),
    { fetchDevices },
)(injectIntl(DevicesList));
