import Clear from '@mui/icons-material/Clear';
import Done from '@mui/icons-material//Done';
import Warning from '@mui/icons-material/Warning';
import * as React from 'react';
import { DatasetValidationStatus } from '../../../../common/interfaces';
import { useIconStyles } from '../../styles';

export interface DatasetStatusProps {
    status: DatasetValidationStatus;
}

const DatasetStatusIcon: React.FunctionComponent<DatasetStatusProps> = ({ status }) => {
    const classes = useIconStyles();

    switch (status) {
        case 'OK':
            return <Done className={classes.ok}/>;
        case 'ERROR':
            return <Clear className={classes.error}/>;
        case 'WARNING':
            return <Warning className={classes.warning}/>;
        default:
            return null;
    }
};

export default DatasetStatusIcon;
