import FolderOpen from '@mui/icons-material/FolderOpen';
import TabletAndroid from '@mui/icons-material/TabletAndroid';
import { SidebarItem } from '@realcity/web-frame/lib/components/Sidebar/SidebarItem';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const MainMenu: React.FunctionComponent<{}> = () => (
    <>
        <SidebarItem icon={TabletAndroid} text={<FormattedMessage id="menu.devices"/>} path="/devices"/>
        <SidebarItem icon={FolderOpen} text={<FormattedMessage id="menu.datasets"/>} path="/datasets"/>
    </>
);

export default MainMenu;
