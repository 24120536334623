import { MaterialReactTable, MaterialReactTableProps, MRT_Row, MRT_TableInstance } from 'material-react-table';
import * as React from 'react';
import { useCallback, useRef } from 'react';
import useMRTLocalization from '@realcity/web-frame/lib/hooks/useMRTLocalization';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Refresher from '@realcity/web-frame/lib/components/Refresher';
import { FetchDataType } from '../store/reducers';
import CommonTableToolbar from './CommonTableToolbar';
import { IntlShape, useIntl } from 'react-intl';
import { COMMON_DATE_FORMAT, handleCsvExport } from '../utils';
import { useReactToPrint } from 'react-to-print';

type Props<T extends Record<string, any>> = MaterialReactTableProps<T> & {
    title: JSX.Element,
    fetchResult: FetchDataType<T[]>,
    refresh: () => void,
};

function mapValueToCsvString(value: unknown, intl: IntlShape): string {
    if (value == null) {
        return '';
    }

    if (value instanceof Date) {
        return intl.formatTime(value, { ...COMMON_DATE_FORMAT });
    }

    return (value as object).toString();
}

const CommonTable = <T extends Record<string, any> = {}>(props: Props<T>) => {
    const { title, fetchResult, refresh, initialState, columns, data, ...rest } = props;
    const { error, lastLoadTime, loading } = fetchResult;
    const localization = useMRTLocalization();

    const intl = useIntl();

    const tableBodyRef = useRef<HTMLTableSectionElement>(null);

    const handlePrint = useReactToPrint({
        content: () => tableBodyRef.current,
    });

    const exportToCsv = (rows: MRT_Row<T>[]) => {
        const csvdata = rows.map((row) => {
            const mappedRow = {};
            for (const cell of row.getAllCells()) {
                const key = cell.column.id;
                if (key === 'mrt-row-actions') {
                    continue;
                }

                const value = cell.getValue();
                mappedRow[key] = mapValueToCsvString(value, intl);
            }
            return mappedRow;
        });

        handleCsvExport(columns, csvdata);
    };

    const renderToolbarInternalActions = ({ table }: { table: MRT_TableInstance<T> }) => {

        const onCsvExport = () => {
            exportToCsv(table.getPrePaginationRowModel().rows);
        };

        return (
            <CommonTableToolbar table={table} onCsvExport={onCsvExport} onPrint={handlePrint} intl={intl} />
        );
    };

    const renderTopToolbarCustomActions = useCallback(
        () => (
            <Stack direction="row" alignItems="center">
                <Typography variant="h6">
                    {title}
                </Typography>
                <Refresher
                    error={error}
                    interval={10000}
                    refresh={refresh}
                    loading={loading}
                    lastRefresh={lastLoadTime}
                />
            </Stack>
        ),
        [error, refresh, loading, lastLoadTime],
    );

    return (
        <MaterialReactTable
            columns={columns}
            data={data}
            localization={localization}
            initialState={{
                ...initialState,
                density: 'compact',
            }}
            muiTablePaperProps={{
                square: true,
                elevation: 0,
                sx: { flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' },
            }}
            muiTableContainerProps={{ sx: { flex: 1 } }}
            muiTableHeadRowProps={{ sx: { boxShadow: 'none' } }}
            enablePagination={false}
            enableStickyHeader
            enableColumnActions={false}
            enableDensityToggle={false}
            enableFullScreenToggle={false}
            enableHiding
            enableFilters
            enableSorting
            enableColumnOrdering={false}
            enableTopToolbar
            enableBottomToolbar={false}
            enableGlobalFilter
            state={{ isLoading: loading && !lastLoadTime }}
            renderTopToolbarCustomActions={renderTopToolbarCustomActions}
            renderToolbarInternalActions={renderToolbarInternalActions}
            muiTableBodyProps={{
                ref: tableBodyRef,
            }}
            {...rest}
        />
    );
};

export default CommonTable;
