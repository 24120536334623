import { Snackbar, SnackbarContent, SnackbarOrigin, SnackbarProps } from '@mui/material';
import amber from '@mui/material/colors/amber';
import green from '@mui/material/colors/green';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import CheckCircleIcon from '@mui/material/Icon';
import CloseIcon from '@mui/material/Icon';
import ErrorIcon from '@mui/material/Icon';
import InfoIcon from '@mui/material/Icon';
import WarningIcon from '@mui/material/Icon';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { closeCommandNotification } from '../../store/actions';

export type NotificationSnackbarOrigin = 'bottom-left' | 'bottom-right';
export type NotificationSnackbarVariant = 'success' | 'error' | 'info' | 'warning';

interface Props extends SnackbarProps {
    notificationId: number;
    origin: NotificationSnackbarOrigin;
    variant: NotificationSnackbarVariant;
    closeModificationNotification: typeof closeCommandNotification;
}

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    variantIcon: {
        opacity: 0.9,
        marginRight: theme.spacing(),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const SNACKBAR_ORIGIN = {
    horizontal: 'left',
    vertical: 'bottom',
} as SnackbarOrigin;

const NotificationSnackbar: React.FunctionComponent<Props> = (props) => {
    const { children, origin, onClose, className, variant, notificationId, closeModificationNotification, ...rest } = props;

    const classes = useStyles();
    const Icon = variantIcon[variant];

    const onClick = () => {
        closeModificationNotification(notificationId);
    };

    React.useEffect(() => {
        setTimeout(() => {
            closeModificationNotification(notificationId);
        },         5000);
    },              []);

    return (
        <Snackbar anchorOrigin={SNACKBAR_ORIGIN} open onClose={onClose} {...rest}>
            <SnackbarContent
                className={classNames(classes[variant], className)}
                message={(
                    <span className={classes.message}>
                        <Icon className={classNames(classes.icon, classes.variantIcon)}/>
                        {children}
                    </span>
                )}
                action={(
                    <div className={classes.close}>
                        <IconButton color="inherit" onClick={onClick}>
                            <CloseIcon className={classes.icon}/>
                        </IconButton>
                    </div>
                )}
            />
        </Snackbar>
    );
};

export default connect(
    null,
    { closeModificationNotification: closeCommandNotification })
(NotificationSnackbar);
