import { Action, Dispatch } from 'redux';
import { ExtendedDataset, ExtendedDevice, DeviceConfigCommandPayload } from '../../../common/interfaces';
import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { AUTHENTICATION_DISABLED } from '../config';
import { Command } from './reducers';
import { AppState, CommandType } from './state';

export interface BaseSuccessAction<T> extends Action {
    data: T;
    time: Date;
}

export interface BaseFailedAction extends Action {
    error: any;
}

export interface DevicesFetchStartAction extends Action {
    type: 'FETCH_DEVICES_START';
}

export interface DevicesFetchSuccessAction extends BaseSuccessAction<ExtendedDevice[]> {
    type: 'FETCH_DEVICES_SUCCESS';
}

export interface DevicesFetchFailedAction extends BaseFailedAction {
    type: 'FETCH_DEVICES_FAILED';
}

export interface DatasetsFetchStartAction extends Action {
    type: 'FETCH_DATASETS_START';
}

export interface DatasetsFetchSuccessAction extends BaseSuccessAction<ExtendedDataset[]> {
    type: 'FETCH_DATASETS_SUCCESS';
}

export interface DatasetsFetchFailedAction extends BaseFailedAction {
    type: 'FETCH_DATASETS_FAILED';
}

export interface CommandStartAction extends Action {
    type: 'COMMAND_START';
}

export interface CommandSuccessAction extends BaseSuccessAction<Command> {
    type: 'COMMAND_SUCCESS';
    commandId: number;
    target: string;
    commandType: CommandType;
}

export interface CommandFailedAction extends BaseFailedAction {
    type: 'COMMAND_FAILED';
    commandId: number;
    target: string;
    commandType: CommandType;
}

export interface CommandClearAction extends Action {
    type: 'COMMAND_CLEAR';
}

export interface CloseCommandNotificationAction extends Action {
    type: 'COMMAND_CLOSE_NOTIFICATION';
    id: number;
}

interface FetchParameters<T> {
    actionTypes: [StartAction['type'], SuccessAction['type'], FailedAction['type']];
}

interface CommandParameters {
    commandType: CommandType;
    target: string;
}

export type SuccessAction = DatasetsFetchSuccessAction | DevicesFetchSuccessAction | CommandSuccessAction;
export type FailedAction = DatasetsFetchFailedAction | DevicesFetchFailedAction | CommandFailedAction;
export type StartAction = DatasetsFetchStartAction | DevicesFetchStartAction | CommandStartAction;

type BasicPromiseProvider = (config: AxiosRequestConfig) => AxiosPromise;
type PromiseProvider<T> = (config: AxiosRequestConfig) => AxiosPromise<T>;

export function fetchDevices() {
    return fetch<ExtendedDevice[]>({
        actionTypes: ['FETCH_DEVICES_START', 'FETCH_DEVICES_SUCCESS', 'FETCH_DEVICES_FAILED'],
    },                             config => axios.get('/api/extended-devices', config));
}

export function fetchDatasets() {
    return fetch<ExtendedDataset[]>({
        actionTypes: ['FETCH_DATASETS_START', 'FETCH_DATASETS_SUCCESS', 'FETCH_DATASETS_FAILED'],
    },                              config => axios.get('/api/extended-datasets', config));
}

export const updateDeviceConfig = (deviceId: string, deviceConfig: DeviceConfigCommandPayload): Action => {
    return modify({
        commandType: 'update-device-config',
        target: deviceId,
    },
                  config => axios.patch(`/api/devices/${deviceId}/config`, deviceConfig, config)) as any;
};

export const uploadDeviceLog = (deviceId: string): Action => {
    return modify({
        commandType: 'upload-log',
        target: deviceId,
    },
                  config => axios.post(`/api/devices/${deviceId}/upload-log`, {}, config)) as any;
};

export const closeCommandNotification = (id: number): CloseCommandNotificationAction => ({
    type: 'COMMAND_CLOSE_NOTIFICATION',
    id,
});

export const clearCommand = (): CommandClearAction => ({
    type: 'COMMAND_CLEAR',
});

function fetch<T>(parameters: FetchParameters<T>, promiseProvider: PromiseProvider<T[]>):
    (dispatch: Dispatch<AppAction>, getState: () => AppState) => void {
    return async (dispatch: Dispatch<AppAction>, getState: () => AppState) => {
        dispatch(createFetchStartAction(parameters.actionTypes[0]));
        try {
            const requestConfig = await getRequestConfig(getState);
            const response = await promiseProvider(requestConfig);
            dispatch(createFetchSuccessAction<T[]>(parameters.actionTypes[1], response.data));
        } catch (e) {
            dispatch(createFetchFailedAction(parameters.actionTypes[2], e));
        }
    };
}

let latestCommandId = 0;
function modify(parameters: CommandParameters, promiseProvider: BasicPromiseProvider):
    (dispatch: Dispatch<AppAction>, getState: () => AppState) => void {
    return async (dispatch: Dispatch<AppAction>, getState: () => AppState) => {
        const commandId = latestCommandId++;
        dispatch({
            type: 'COMMAND_START',
        });
        try {
            const requestConfig = await getRequestConfig(getState);
            await promiseProvider(requestConfig);
            dispatch({
                type: 'COMMAND_SUCCESS',
                commandId,
                commandType: parameters.commandType,
                target: parameters.target,
                data: {
                    state: 'success',
                },
                time: new Date(),
            });
        } catch (e) {
            dispatch({
                type: 'COMMAND_FAILED',
                commandId,
                commandType: parameters.commandType,
                error: e,
                target: parameters.target,
            });
        }
    };
}

async function getRequestConfig(getState: () => AppState) {
    if (AUTHENTICATION_DISABLED) {
        return {};
    }

    const keycloak = getState().auth.keycloak!;
    await keycloak.updateToken(30);

    return {
        headers: {
            Authorization: `Bearer ${keycloak.token}`,
        },
    };
}

function createFetchFailedAction(type: BaseFailedAction['type'], error: any): BaseFailedAction {
    return { type, error };
}

function createFetchSuccessAction<T>(type: BaseSuccessAction<T>['type'], data: T): BaseSuccessAction<T> {
    return { type, data, time: new Date() };
}

function createFetchStartAction(type: StartAction['type']): Action {
    return { type };
}

export type AppAction =
    StartAction |
    SuccessAction |
    FailedAction |
    CommandClearAction |
    CloseCommandNotificationAction;
