import { configureStore } from '@realcity/web-frame';
import { AuthWrapper, RequireAuth } from '@realcity/web-frame/lib/components/Auth';
import IfRoleOrUnauthorizedApplicationMessage from '@realcity/web-frame/lib/components/Auth/IfRoleOrUnauthorizedApplicationMessage';
import { Base } from '@realcity/web-frame/lib/components/Base';
import CustomTheme from '@realcity/web-frame/lib/theme/CustomTheme';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import { AUTHENTICATION_DISABLED } from './config';
import MainMenu from './MainMenu';
import reducers from './store/reducers';
import IntlProvider from '@realcity/web-frame/lib/components/Intl/Provider';
import { theme } from './styles';
import messages_en from './translations/en.json';
import messages_hu from './translations/hu.json';
import DevicesList from './screens/DevicesList';
import DataSetsList from './screens/DataSetsList';
import { Roles } from './constants';

const messages = {
    hu: messages_hu,
    en: messages_en,
};

const store = configureStore(reducers);

const Home = () => (<Redirect to="/devices" />);

const authOptions = {};

const App: React.FunctionComponent = () => (
    <Provider store={store}>
        <IntlProvider supportedLocales={['en', 'hu']} messages={messages}>
            <AuthWrapper options={authOptions} authenticationDisabled={AUTHENTICATION_DISABLED}>
                <RequireAuth>
                    <IfRoleOrUnauthorizedApplicationMessage role={Roles.Read}>
                        <BrowserRouter>
                            <CustomTheme theme={theme}>
                                <Base
                                    appName="OBU Manager"
                                    appVersion={process.env.REACT_APP_VERSION!}
                                    privateApp
                                    useFeedback={false}
                                    menu={<MainMenu />}
                                >
                                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflowX: 'auto' }}>
                                        <Route path="/" exact={true} component={Home} />
                                        <Route path="/devices" component={DevicesList} />
                                        <Route path="/datasets" component={DataSetsList} />
                                    </div>
                                </Base>
                            </CustomTheme>
                        </BrowserRouter>
                    </IfRoleOrUnauthorizedApplicationMessage>
                </RequireAuth>
            </AuthWrapper>
        </IntlProvider>
    </Provider>
);

export default App;
