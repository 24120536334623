import { getConfig } from '@realcity/web-frame/lib/config';

interface AppConfig {
    AUTHENTICATION_DISABLED: string;
}

interface FlavorConfig {
}

export const APP_CONFIG = getConfig<AppConfig>();

// TODO: Flavor config
export const FLAVOR_CONFIG: FlavorConfig = {};

// Global config
const CONFIG: AppConfig & FlavorConfig = { ...APP_CONFIG, ...FLAVOR_CONFIG };

export const AUTHENTICATION_DISABLED = APP_CONFIG.AUTHENTICATION_DISABLED === 'true';

export default CONFIG;
