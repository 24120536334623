import * as React from 'react';
import { ActivityStatus } from '../../../../common/interfaces';
import Wifi from '@mui/icons-material/Wifi';
import WifiOff from '@mui/icons-material/WifiOff';
import { useIconStyles } from '../../styles';

const DeviceActivityIcon: React.FunctionComponent<{ activityStatus: ActivityStatus }> = (props) => {
    const { activityStatus } = props;
    const classes = useIconStyles();
    return activityStatus === 'ACTIVE' ? <Wifi className={classes.ok} /> : <WifiOff className={classes.error} />;
};

export default DeviceActivityIcon;
