import * as React from 'react';

interface Props {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel = (props: Props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
};

export default TabPanel;
