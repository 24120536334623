import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import DatasetStatusIcon, { DatasetStatusProps } from './DatasetStatusIcon';

interface Props extends DatasetStatusProps {
    datasetVersion: React.ReactNode;
}

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        display: 'flex',
        paddingRight: 10,
    },
});

const DatasetCell: React.FunctionComponent<Props> = ({ status, datasetVersion }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.icon}>
                <DatasetStatusIcon status={status}/>
            </div>
            <div>
                {datasetVersion}
            </div>
        </div>
    );
};

export default DatasetCell;
