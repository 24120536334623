import {
    MRT_ShowHideColumnsButton,
    MRT_TableInstance,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from 'material-react-table';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloudDownload from '@mui/icons-material/CloudDownload';
import Print from '@mui/icons-material/Print';
import * as React from 'react';
import { IntlShape } from 'react-intl';

interface Props {
    intl: IntlShape;
    onCsvExport: () => void;
    onPrint: () => void;
    table: MRT_TableInstance<any>;
}

const CommonTableToolbar: React.FC<Props> = ({ intl, onCsvExport, onPrint, table }) => (
    <>
        <MRT_ToggleGlobalFilterButton table={table} />
        <Tooltip title={intl.formatMessage({ id: 'table.download-csv' })} arrow>
            <IconButton onClick={onCsvExport}>
                <CloudDownload />
            </IconButton>
        </Tooltip>
        <Tooltip title={intl.formatMessage({ id: 'table.print' })} arrow>
            <IconButton onClick={onPrint}>
                <Print />
            </IconButton>
        </Tooltip>
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleFiltersButton table={table} />
    </>
);

export default CommonTableToolbar;
