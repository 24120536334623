import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Close from '@mui/icons-material/Close';
import * as React from 'react';
import { useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { DeviceConfig, ExtendedDevice } from '../../../../common/interfaces';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import ReactJson from 'react-json-view';
import IconButton from '@mui/material/IconButton';
import { FormattedMessage } from 'react-intl';
import { clearCommand, updateDeviceConfig } from '../../store/actions';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import { Command } from '../../store/reducers';
import { AppState } from '../../store/state';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TabPanel from '../../components/TabPanel';

interface Props {
    open: boolean;
    device: ExtendedDevice;
    latestCommand: Command;
    updateDeviceConfig: typeof updateDeviceConfig;
    clearCommand: typeof clearCommand;
    onClose: () => void;
}

const useStyles = makeStyles(theme => ({
    title: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
    },
    closeButton: {
        color: theme.palette.grey[500],
        alignSelf: 'start',
    },
    dialogContent: {
        display: 'flex',
        overflow: 'hidden',
    },
    content: {
        overflow: 'auto',
        padding: theme.spacing(2),
    },
    jsonTextArea: {
        minWidth: '500px',
    },
    formTextField: {
        display: 'table-cell',
        verticalAlign: 'middle',
        minWidth: '500px',
    },
}));

const isTestDriveEnabled = (config?: DeviceConfig) => {
    if (!config || !config.coreServices || !config.testdrive) {
        return false;
    }

    return config.coreServices.location === 'TestDrive' && config.testdrive.enabled;
};

const getVehicleId = (config?: DeviceConfig) => {
    if (!config || !config.coreSettings) {
        return '';
    }

    return config.coreSettings.vehicleId;
};

const getTestDriveUrl = (config?: DeviceConfig) => {
    if (!config || !config.testdrive) {
        return '';
    }

    return config.testdrive.url;
};

interface ConfigState {
    configText: string;
    configObject: DeviceConfig;
    jsonValid: boolean;
}

type ConfigUpdateAction = { type: 'TEXT', value: string } | { type: 'OBJECT', value: unknown };

const configReducer: (state: ConfigState, action: ConfigUpdateAction) => ConfigState = (state, action) => {
    switch (action.type) {
        case 'TEXT': {
            const { value } = action;
            try {
                const configObject = JSON.parse(value);
                return {
                    configObject,
                    configText: value,
                    jsonValid: true,
                };
            } catch (e) {
                return {
                    configObject: state.configObject,
                    configText: value,
                    jsonValid: false,
                };
            }
        }
        case 'OBJECT': {
            const { value } = action;
            const currentConfigState = cloneDeep(state.configObject);
            merge(currentConfigState, value);

            return {
                configObject: currentConfigState,
                configText: JSON.stringify(currentConfigState, null, 2),
                jsonValid: state.jsonValid,
            };
        }
    }
};

const ConfigurationDialog = (props: Props) => {
    const { device, latestCommand, onClose, open, updateDeviceConfig, clearCommand } = props;
    const classes = useStyles();

    const [activeTab, setActiveTab] = useState(0);
    const [shouldRestartDevice, setShouldRestartDevice] = useState(true);

    const getInitialState = () => {
        const originalConfig = device.config || {};
        return {
            configText: JSON.stringify(originalConfig, null, 2),
            configObject: originalConfig,
            jsonValid: true,
        };
    };

    const [configState, dispatchConfig] = useReducer(configReducer, {}, getInitialState);

    const cleanupAndClose = () => {
        clearCommand();
        onClose();
    };

    const configHasChanged = () => {
        return !isEqual(device.config, configState.configObject);
    };

    const onTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    };

    const changeShouldRestartDevice = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShouldRestartDevice(event.target.checked);
    };

    const updateConfigState = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatchConfig({ type: 'TEXT', value: event.target.value });
    };

    const updateConfigParts = (updatedConfig: unknown) => {
        dispatchConfig({ type: 'OBJECT', value: updatedConfig });
    };

    const changeVehicleId = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateConfigParts({
            coreSettings: {
                vehicleId: event.target.value,
            },
        });
    };

    const changeTestDriveEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateConfigParts({
            coreServices: {
                location: 'TestDrive',
            },
            testdrive: {
                enabled: event.target.checked,
            },
        });
    };

    const changeTestDriveUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateConfigParts({
            testdrive: {
                url: event.target.value,
            },
        });
    };

    const handleClick = () => {
        updateDeviceConfig(device.deviceId, {
            config: configState.configObject,
            restart: shouldRestartDevice,
        });
        return;
    };

    if (open && latestCommand.state === 'success') {
        cleanupAndClose();
    }

    const configStateInvalid = !configState.configObject || !configState.jsonValid;

    return (
        <Dialog open={open} onClose={cleanupAndClose}>
            <DialogTitle id="simple-dialog-title" className={classes.title}>
                <FormattedMessage
                    id="devices.configuration.title"
                    values={{ deviceId: device.deviceId, deviceName: device.deviceName }}
                />
                <IconButton className={classes.closeButton} onClick={cleanupAndClose} size="small">
                    <Close />
                </IconButton>
            </DialogTitle>
            <Stack direction="column" className={classes.dialogContent}>
                <div>
                    <Tabs value={activeTab} onChange={onTabChange} variant="fullWidth">
                        <Tab
                            label={<FormattedMessage id="devices.configuration.tab-json.title" />}
                            disabled={configStateInvalid}
                        />
                        <Tab label={<FormattedMessage id="devices.configuration.tab-edit.title" />} />
                        <Tab
                            label={<FormattedMessage id="devices.configuration.tab-options.title" />}
                            disabled={configStateInvalid}
                        />
                    </Tabs>
                </div>
                <Box className={classes.content}>
                    <TabPanel value={activeTab} index={0}>
                        <ReactJson
                            src={configState.configObject}
                            name={false}
                            displayDataTypes={false}
                            displayObjectSize={false}
                        />
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                        <TextField
                            error={!configState.jsonValid}
                            className={classes.jsonTextArea}
                            multiline
                            variant="outlined"
                            value={configState.configText}
                            onChange={updateConfigState}
                            helperText={!configState.jsonValid &&
                                <FormattedMessage id="devices.configuration.tab-json.invalid" />}
                        />
                    </TabPanel>
                    <TabPanel value={activeTab} index={2}>
                        <FormControl>
                            <Grid container direction="column" alignItems="flex-start" spacing={3}>
                                <Grid item>
                                    <TextField
                                        className={classes.formTextField}
                                        variant="outlined"
                                        label={<FormattedMessage id="devices.configuration.tab-options.vehicle-id" />}
                                        value={getVehicleId(configState.configObject)}
                                        fullWidth
                                        onChange={changeVehicleId}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        className={classes.formTextField}
                                        variant="outlined"
                                        label={(
                                            <FormattedMessage
                                                id="devices.configuration.tab-options.test-drive-url"
                                            />
                                        )}
                                        value={getTestDriveUrl(configState.configObject)}
                                        fullWidth
                                        onChange={changeTestDriveUrl}
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={(
                                            <Checkbox
                                                checked={isTestDriveEnabled(configState.configObject)}
                                                color="primary"
                                                onChange={changeTestDriveEnabled}
                                            />)}
                                        label={(
                                            <FormattedMessage
                                                id="devices.configuration.tab-options.test-drive-enabled"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </FormControl>
                    </TabPanel>
                </Box>
            </Stack>
            <DialogActions>
                <FormControlLabel
                    control={(
                        <Checkbox
                            checked={shouldRestartDevice}
                            color="primary"
                            onChange={changeShouldRestartDevice}
                        />)}
                    label={<FormattedMessage id="devices.configuration.should-restart-device" />}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                    disabled={!configHasChanged() || !configState.jsonValid}
                >
                    <FormattedMessage id="devices.configuration.confirm" />
                    {latestCommand.state === 'in-progress' &&
                        <CircularProgress color={'secondary'} size={24} />
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connect(
    ({ latestCommand }: AppState) => ({ latestCommand }),
    { updateDeviceConfig, clearCommand })
(ConfigurationDialog);
