import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import RealCityTheme from '@realcity/web-frame/lib/themes/RealCity';

declare module '@mui/styles/defaultTheme' {
    interface DefaultTheme extends Theme {}
}

export const useIconStyles = makeStyles({
    error: {
        color: 'red',
    },
    ok: {
        color: 'green',
    },
    warning: {
        color: 'orange',
    },
});

const themeOptions: ThemeOptions = {
    components: {
        MuiTableCell: {
            styleOverrides: {
                head: {
                    padding: '10px 10px 10px 10px',
                },
                root: {
                    padding: '5px 5px 5px 10px',
                },
            },
        },
    },
};

export const theme = createTheme(RealCityTheme, themeOptions);
