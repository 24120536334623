import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';

interface Props {
    text: string;
}

const useStyles = makeStyles({
    text: {
        maxWidth: '250px',
        wordWrap: 'break-word',
    },
});

const LongText: React.FunctionComponent<Props> = ({ text }) => {
    const classes = useStyles();

    return <div className={classes.text}>{text}</div>;
};

export default LongText;
