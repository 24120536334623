import { MRT_ColumnDef } from 'material-react-table';
import { ExportToCsv, Options as ExportToCsvOptions } from 'export-to-csv';
import { DatasetDetail } from '../../common/interfaces';

export const NOTIFICATION_MESSAGES = {
    success: 'notification-snackbar.success',
    error: 'notification-snackbar.failure',
};

export const COMMON_DATE_FORMAT: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
};

function convertDatasetDetailToString(dataset: DatasetDetail): string {
    if (dataset.version) {
        return `${dataset.version} (${dataset.datasetId!.split('-')[0]})`;
    }

    return dataset.datasetId || '';
}

const DATASET_VERSION_SEPARATOR = ';';

export function convertDatasetDetailsToString(details: DatasetDetail[]): string {
    return details.map(value => convertDatasetDetailToString(value))
        .join(DATASET_VERSION_SEPARATOR);
}

export const handleCsvExport: (columns: MRT_ColumnDef<any>[], csvData: any[]) => void = (columns, csvData) => {
    const csvOptions: ExportToCsvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map(c => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvData);
};
